import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import {Amplify} from "aws-amplify";
// import UUID from "vue-uuid";

Vue.config.productionTip = false;
// Vue.use(UUID);

Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_VmMF1JinB",
    region: "ap-south-1",
    userPoolWebClientId: "bp5iab1u2k5c019p0adfgsvqv",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint:
      "https://dz5he4ditfevjl53f3al5e4ssi.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "Report_issue",
        endpoint: " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
      },
    ],
  },
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <v-app>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-app-bar elevation="1" app color="black">
      <v-img src="@/assets/logo1.png" max-width="190px"></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          align="center"
          justify="center"
        >
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="8" xs="12">
              <v-card class="elevation-0" max-width="500px">
                <v-img src="@/assets/bg.jpeg"></v-img>
                BMS
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="4" xs="12">
              <v-card class="py-6 mt-12 elevation-0" max-width="500px">
                <v-card-title class="pb-6">
                  <v-row class="text-center">
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="12"
                      class="py-0"
                      align="center"
                    >
                      <v-img max-width="100px" src="@/assets/logo2.jpg"></v-img>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="PrimaryFontColor">
                        {{ currentTitle }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card class="elevation-0 mt-3">
                  <v-window v-model="step" class="pa-3">
                    <v-window-item :value="1">
                      <v-form ref="form" lazy-validation class="mt-2">
                        <div class="py-2">Email ID *</div>
                        <v-text-field
                          solo
                          dense
                          :rules="[(v) => !!v || 'Email ID is required']"
                          v-model="Login.EmailID"
                        >
                          <template v-slot:prepend-inner>
                            <v-icon color="primary">mdi-account</v-icon>
                          </template>
                        </v-text-field>
                      </v-form>
                      <v-card-actions class="px-0 py-4">
                        <v-btn
                          block
                          :loading="loadingEmail"
                          tile
                          color="primary"
                          @click="ValidateSignInForm()"
                          >Login</v-btn
                        >
                      </v-card-actions>
                    </v-window-item>
                    <v-window-item :value="2">
                      <v-form ref="formOTP" lazy-validation>
                        <v-card-actions class="pa-0">
                          <div class="font-weight-bold">
                            {{ Login.EmailID }}
                          </div>
                          <v-btn
                            x-small
                            outlined
                            class="mx-2"
                            color="primary"
                            @click="step = 1"
                            >Change</v-btn
                          >
                        </v-card-actions>
                        <div class="py-2">Enter OTP *</div>
                        <v-otp-input
                          dense
                          label=""
                          @click:append="isPwdNew = !isPwdNew"
                          required
                          :rules="[(v) => !!v || 'Password is required']"
                          v-model="Login.Password"
                        >
                          <template v-slot:prepend-inner>
                            <v-icon color="primary">mdi-lock</v-icon>
                          </template>
                        </v-otp-input>
                      </v-form>
                      <v-card-actions class="pa-0 pb-10 pt-4">
                        <v-btn
                          block
                          :loading="loadingPassword"
                          tile
                          color="primary"
                          @click="ValidateConfirmSignIn()"
                          >Login</v-btn
                        >
                      </v-card-actions>
                    </v-window-item>
                  </v-window>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    step: 1,
    OTPEmail: "",
    ConfirmOTP: "",
    isPwdNew: false,
    loadingEmail: false,
    loadingPassword: false,
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    SnackBarComponent: {},
    Login: {
      EmailID: "",
      Password: "",
    },
    Snackbar: {
      SnackbarVmodel: false,
      SnackbarText: "",
      Color: "",
      Top: true,
    },
    user: {},
  }),
  watch: {},
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "BMS - Sign In";
        case 2:
          return "Enter OTP";
        default:
          return "BMS - Sign In";
      }
    },
  },
  mounted() {},
  methods: {
    ValidateConfirmSignIn() {
      if (this.$refs.formOTP.validate()) {
        this.confirmOTP();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter OTP *",
        };
      }
    },
    async confirmOTP() {
      try {
        this.loadingPassword = true;
        await Auth.sendCustomChallengeAnswer(
          this.user,
          this.Login.Password
        ).then((result) => {
          if (result.attributes.email_verified == undefined) {
            this.loadingPassword = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Invalid OTP Entered",
            };
          } else {
            this.ActivateMethod();
            this.loadingPassword = false;
          }
        });
      } catch (error) {
        console.log("error :", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
        this.loadingPassword = false;
      }
    },
    ActivateMethod() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully Logged In!!",
      };
      setTimeout(() => this.$router.push("/Organization"), 300);
    },
    ValidateSignInForm() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Email ID *",
        };
      }
    },
    async SignIn() {
      try {
        this.loadingEmail = true;
        this.user = await Auth.signIn(this.Login.EmailID.toLowerCase().trim());
        this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
        if (this.user.challengeName === "CUSTOM_CHALLENGE") {
          this.step = 2;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent Successfully !",
          };
        }
        this.loadingEmail = false;
      } catch (error) {
        console.log("error :", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Mail ID !!",
        };
        this.loadingEmail = false;
      }
    },
  },
};
</script>

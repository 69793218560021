import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "@/views/MainHomePages/LoginPage.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () => import("@/views/MainHomePages/LandingPage.vue"),
    children: [
      {
        name: "Organization",
        path: "/Organization",
        component: () => import("../views/ChildComponents/Organization.vue"),
      },
      {
        name: "ManageAdmins",
        path: "/ManageAdmins",
        component: () => import("../views/ChildComponents/ManageAdmins.vue"),
      },
      {
        name: "bmsLogs",
        path: "/bmsLogs",
        component: () => import("../views/ChildComponents/bmsLogs.vue"),
      },
      {
        name: "ModuleAccess",
        path: "/ModuleAccess",
        component: () => import("../views/ChildComponents/ModuleAccess.vue"),
      },
      {
        name: "Category",
        path: "/Category",
        component: () => import("../views/ChildComponents/Category.vue"),
      },
      {
        name: "MasterCategory",
        path: "/MasterCategory",
        component: () => import("../views/ChildComponents/MasterCategory.vue"),
      },
      {
        name: "Manufacturer",
        path: "/Manufacturer",
        component: () => import("../views/ChildComponents/Manufacturer.vue"),
      },
      {
        name: "Catalog",
        path: "/Catalog",
        component: () => import("../views/ChildComponents/Catalog.vue"),
      },
      {
        name: "Recharge",
        path: "/Recharge",
        component: () => import("../views/ChildComponents/Recharge.vue"),
      },
      {
        name: "RaydeoIntegration",
        path: "/RaydeoIntegration",
        component: () => import("../views/ChildComponents/RaydeoIntegration.vue"),
      },
      // RaydeoIntegration
      {
        name: "MasterSetting",
        path: "/MasterSetting",
        component: () =>
          import(
            /* webpackChunkName: "Container" */ "../views/ChildComponents/MasterSetting.vue"
          ),
      },
    ],
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: () => import("@/components/ReportIssue.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;

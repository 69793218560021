import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    EmailID: "",
    GetCurrentUser: [],
    GetCurrentOrg: [],
    GetTeamList: [],
    getIssueDetails: {},
    getPreviousRoute: "",
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser
    },
    get_current_org_details: (state) => {
      return state.GetCurrentOrg
    },
    get_team_list: (state) => {
      return state.GetTeamList
    },
    get_issue_details: (state) => {
      return state.getIssueDetails;
    },
    get_previous_route: (state) => {
      return state.getPreviousRoute;
    },
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    GET_CURRENTORG_DETAILS(state, GetCurrentOrgDetails) {
      state.GetCurrentOrg = GetCurrentOrgDetails;
    },
    SET_TEAM_LIST(state, GetTeamListDetails) {
      state.GetTeamList = GetTeamListDetails;
    },
    SET_ISSUE_DETAILS(state, getIssueDetails) {
      state.getIssueDetails = getIssueDetails;
    },
    SET_PREVIOUS_ROUTE(state, route) {
      state.getPreviousRoute = route;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
